<template>
	<div class="container">
		<Nav :curIndex="2"></Nav>
		<div class="account_right">
			<div class="my-address">
				<div class="title">My Address</div>
                <div class="add-btn" @click="add">Add An Address</div>
                <template v-if="list.length > 0">
                    <div v-for="(item,index) in list" :key="index" class="address-item">
                    	<div class="item1">{{item.user_name_first}} {{item.user_name_last}}</div>
                    	<div class="item2">
                    		{{item.address}} {{item.apartment}}
                    		<br/>
                    		({{item.postal_code}})
                    		<br/>
                    		{{item.city}}
                    		<br/>
                    		{{item.state}}
                    		<br/>
                    		{{item.country}}
                    		<br/>
                    		{{item.phone}}
                    	</div>
                    	<div class="btns">
                    		<div class="edit-btn" @click="edit(item.id)">Edit</div>
                    		<div class="delete-btn" @click="deleteAddress(item.id)">Delete</div>
                    	</div>
                    </div>
                </template>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
                list: []
            }
        },
        created() {
            this.getAddress()
        },
        methods: {
            getAddress() {
                this.$api.getAddress({},true).then(res => {
                    this.list = res.data
                })
            },
            
            add() {
				this.$router.push({
				    path: '/AddAddress'
				})
			},
            
            edit(id) {
                this.$router.push({
                    path: '/AddAddress',
                    query: {
                        id
                    }
                })
            },
            
            deleteAddress(id) {
                this.$api.deleteAddress({
                    id
                },true).then(res => {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    })
                    this.list.forEach((item, index) => {
                        if(item.id == id) {
                            this.list.splice(index,1)
                        }
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
        		.my-address {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 3rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.add-btn {
        				display: inline-block;
        				min-width: 9rem;
        				height: 4.5rem;
        				line-height: 4.5rem;
        				box-sizing: border-box;
        				padding: 0 5rem;
                        margin-bottom: 3rem;
        				border-radius: 2px;
        				font-size: 1.5rem;
        				color: #FFFFFF;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #333333;
        				cursor: pointer;
        			}
        			
        			.address-item {
        				width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 3rem;
                        }
        				
        				.item1 {
        					width: 100%;
        					margin-bottom: 1.5rem;
        					font-size: 1.6rem;
        					font-weight: bold;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.item2 {
        					width: 100%;
        					line-height: 160%;
        					font-size: 1.4rem;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.btns {
        					display: flex;
        					width: 100%;
        					margin-top: 1.2rem;
        					
        					.edit-btn {
        						display: flex;
        						justify-content: center;
        						align-items: center;
        					    min-width: 10rem;
        					    height: 3.6rem;
        					    box-sizing: border-box;
        					    padding: 0 1.5rem;
        						border: 2px solid #333333;
        					    border-radius: 2px;
        					    font-size: 1.4rem;
        					    color: #333333;
        					    text-align: center;
        					    white-space: nowrap;
        					    background-color: #FFFFFF;
        					    cursor: pointer;
        						
        						&:hover {
        							color: #FFFFFF;
        							background-color: #333333;
        						}
        					}
        					
        					.delete-btn {
        						display: flex;
        						justify-content: center;
        						align-items: center;
        					    min-width: 10rem;
        					    height: 3.6rem;
        					    box-sizing: border-box;
        					    padding: 0 1.5rem;
        						margin-left: 1rem;
        					    border: 2px solid #333333;
        					    border-radius: 2px;
        					    font-size: 1.4rem;
        					    color: #333333;
        					    text-align: center;
        					    white-space: nowrap;
        					    background-color: #FFFFFF;
        					    cursor: pointer;
        						
        						&:hover {
        							color: #FFFFFF;
        							background-color: #333333;
        						}
        					}
        				}
        			}
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.my-address {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 2.5rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.add-btn {
        				display: inline-block;
        				min-width: 9rem;
        				height: 4.5rem;
        				line-height: 4.5rem;
        				box-sizing: border-box;
        				padding: 0 2.5rem;
                        margin-bottom: 2.5rem;
        				border-radius: 2px;
        				font-size: 1.4rem;
        				color: #FFFFFF;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #333333;
        				cursor: pointer;
        			}
        			
        			.address-item {
        				width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 2.5rem;
                        }
        				
        				.item1 {
        					width: 100%;
        					margin-bottom: 1.2rem;
        					font-size: 1.6rem;
        					font-weight: bold;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.item2 {
        					width: 100%;
        					line-height: 150%;
        					font-size: 1.4rem;
        					color: #333333;
        					word-break: break-all;
        				}
        				
        				.btns {
        					display: flex;
                            justify-content: space-between;
        					width: 100%;
        					margin-top: 1rem;
        					
        					.edit-btn {
        						display: flex;
        						justify-content: center;
        						align-items: center;
        					    width: 48%;
        					    height: 3.6rem;
        					    box-sizing: border-box;
        					    padding: 0 1rem;
        						border: 2px solid #333333;
        					    border-radius: 2px;
        					    font-size: 1.4rem;
        					    color: #333333;
        					    text-align: center;
        					    white-space: nowrap;
        					    background-color: #FFFFFF;
        					    cursor: pointer;
        						
        						&:hover {
        							color: #FFFFFF;
        							background-color: #333333;
        						}
        					}
        					
        					.delete-btn {
        						display: flex;
        						justify-content: center;
        						align-items: center;
        					    width: 48%;
        					    height: 3.6rem;
        					    box-sizing: border-box;
        					    padding: 0 1rem;
        					    border: 2px solid #333333;
        					    border-radius: 2px;
        					    font-size: 1.4rem;
        					    color: #333333;
        					    text-align: center;
        					    white-space: nowrap;
        					    background-color: #FFFFFF;
        					    cursor: pointer;
        						
        						&:hover {
        							color: #FFFFFF;
        							background-color: #333333;
        						}
        					}
        				}
        			}
        		}
        	}
        }
    }
</style>